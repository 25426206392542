import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice, isFulfilled } from '@reduxjs/toolkit'
import { NavCategoriesInfo } from "../app/commonInterfaces";
import { RootState } from '../app/store';
import axios from 'axios';

interface NavCategoriesState {
  navCategories: NavCategoriesInfo[];
}
  
const adapter = createEntityAdapter<NavCategoriesInfo>(
  {
    // Assume IDs are stored in a field other than `book.id`
    selectId: (navCategory) => navCategory.id,
    // Keep the "all IDs" array sorted based on book titles
    sortComparer: (a, b) => a.id.localeCompare(b.id),
  }
)

const initialState = adapter.getInitialState({
  status: 'idle',
  error: null
})

/*export const fetchNavCategory = createAsyncThunk('navCategories/fetchEntities', async () => {
  const requestUrl = "/api/navCategories.json";
  console.log("request : " + requestUrl);
  return axios.get<NavCategoriesInfo[]>(requestUrl);
});*/

export const fetchNavCategory = createAsyncThunk('navCategories/fetchEntities', async (init: NavCategoriesInfo[]) => {  
  return init;
});

export const navSlice = createSlice({
  name: 'navCategory',
  initialState: initialState,
  reducers: {
    navCategoriesAdd: adapter.addOne,
    navCategoriesReceived(state, action) {    
      adapter.setAll(state, action.payload.items)
    },
    navCategoriesUpdate: (state, action) => {
      adapter.setAll(state, action.payload)
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(fetchNavCategory), (state, action) => {
        state.status = 'succeeded'
        console.log("action: " + JSON.stringify(action.payload))
        adapter.setAll(state, action.payload)
      })
  }
})

export default navSlice.reducer

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllNavCategories,
  selectById: selectNavCategoryById,
  selectIds: selectNavCategoriesIds
  // Pass in a selector that returns the posts slice of state
} = adapter.getSelectors<RootState>(state => state.navCategoryReducer)

export const {
  navCategoriesAdd,
  navCategoriesReceived,
  navCategoriesUpdate
} = navSlice.actions



