import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice, isFulfilled } from '@reduxjs/toolkit'
import { VideoInfo } from "../app/commonInterfaces";
import { RootState } from '../app/store';
import axios from 'axios';

interface VideoState {
  videos: VideoInfo[];
}

const adapter = createEntityAdapter<VideoInfo>(
  {
    // Assume IDs are stored in a field other than `book.id`
    selectId: (item) => item.id,
    // Keep the "all IDs" array sorted based on book titles
    sortComparer: (a, b) => a.title.localeCompare(b.title),
  }
)

const initialState = adapter.getInitialState({
  status: 'idle',
  error: null
})

export const fetchVideos = createAsyncThunk('videos/fetchEntities', async () => {
  const requestUrl = "/api/videos.json";
  console.log("request : " + requestUrl);
  return axios.get<VideoInfo[]>(requestUrl);
});

export const videoSlice = createSlice({
  name: 'videos',
  initialState: initialState,
  reducers: {
    videoAdded: adapter.addOne,
    videosReceived(state, action) {
      console.log("action: " + JSON.stringify(action))
      adapter.setAll(state, action.payload.videos)
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(fetchVideos), (state, action) => {
        state.status = 'succeeded'
        console.log("action1: " + JSON.stringify(action.payload.data))
        adapter.setAll(state, action.payload.data)
      })
  }
})

export default videoSlice.reducer

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllVideos,
  selectById: selectVideoById,
  selectIds: selectVideoIds
  // Pass in a selector that returns the posts slice of state
} = adapter.getSelectors<RootState>(state => state.videoReducer)



