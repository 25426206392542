import * as React from 'react';

import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function AboutMe() {

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const getStyle = () => {
    return isSmUp ? { display: 'flex' } : null
  }

  const getStyle2 = () => {
    return isSmUp ? { my: 5, mx: 5 } : { my: 1, mx: 0 }
  }

  return (
    <Typography sx={getStyle2} color="text.secondary" align="center">
      <Card>
        <CardContent sx={getStyle}>
          <CardMedia
            component="img"
            sx={{
              height: 333,
              width: 350,
              maxHeight: { xs: 333, md: 290 },
              maxWidth: { xs: 350, md: 250 },
            }}
            image="./media/me001.jpg"
            alt="Ekaterina Nalitkina"
          />
          <Typography variant="body2" sx={{ my: 5, mx: 5 }}>
            <Typography paragraph align='left'>
              Hey, my name is Kate Nalitkina (she/her)
            </Typography>
            <Typography paragraph align='left'>
              I`m a self-started professional video editor and artist with over 3 years of experience in post-production for social media.
              Willing to provide creative and technical solutions for creative digital content.
            </Typography>
            <Typography paragraph align='left'>
              I was born and raised in Siberia then moved to the US to search for my own way in contemporary art.
              With a passion for traditional drawing, painting, and art history as a student at Novosibirsk Art College, I successfully exhibited works in the annual painting competition and got a mayor's scholarship.
              During the fine arts educational course, I expanded my horizons and started to work with different mediums, techniques and video art materials.
            </Typography>
          </Typography>
        </CardContent>
        <CardContent sx={getStyle}>
          <Typography variant="body2" sx={{ my: 0, mx: 5 }}>
            <Typography paragraph align='left'>
              For a few years I was involved in some independent projects for the Art center in Rockville,
              created promotion videos for gallery accounts on Instagram and made a couple documentary films with alumni of the
              School of Documentary Film and Theater of Marina Razbezhkina and Mikhail Ugarov.
              My projects are based on documentary videos and photos.
              I believe that a new visual experience influences society and makes us a little bit better.
              Contact me to collaborate and make some unique projects, movies, music videos, promos.
            </Typography>
            <Typography paragraph align='left'>
              Thank you! See you soon.
            </Typography>
          </Typography>
          <CardMedia
            component="img"
            sx={{
              height: 333,
              width: 350,
              maxHeight: { xs: 333, md: 290 },
              maxWidth: { xs: 350, md: 250 },
            }}
            image="./media/me002.jpg"
            alt="Ekaterina Nalitkina"
          />
        </CardContent>
      </Card>
    </Typography>
  );
}