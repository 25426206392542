import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PublicIcon from '@mui/icons-material/Public';
import Avatar from '@mui/material/Avatar';
import { NavCategoriesInfo, NavInfo } from '../app/commonInterfaces';
import { useAppDispatch, useAppSelector } from '../app/store';
import { fetchNavCategory, selectAllNavCategories, navCategoriesUpdate } from '../reducer/navCategorySlice'
import { item, itemCategory } from '../app/constants';
import { useNavigate } from 'react-router';
import theme from '../theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const categories =
  [
    {
      id: 'Select one:',
      children: [
        {
          id: 'About me',
          icon: <PublicIcon />,
          url: '/',
          active: true
        },
        {
          id: 'Videos',
          icon: <DnsRoundedIcon />,
          url: '/video',
          active: false
        },
        {
          id: 'Login',
          icon: <PeopleIcon />,
          url: '/login',
          active: false
        }
      ]
    }
  ]


export default function Navigator(props: DrawerProps) {
  const { onClose, ...other } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  React.useEffect(() => {
    dispatch(fetchNavCategory(categories));
  }, []);

  const navCategories: NavCategoriesInfo[] = useAppSelector(selectAllNavCategories)

  const handleSelectClick = (childId: string) => {
    console.log(`handleSelectClick: ${childId}`)
    const nextCategories = navCategories.map(categ => {
      return {
        ...categ,
        children: categ.children.map(child => { return { ...child, active: childId == child.id } })
      }
    })
    console.log(`handleSelectClick nextCategories: ${JSON.stringify(nextCategories)}`)
    dispatch(navCategoriesUpdate(nextCategories));
    const nextItem = nextCategories[0].children.find(child => childId == child.id)
    console.log(`nextItem: ${nextItem?.url}`)
    navigate(nextItem?.url ? nextItem?.url : "/");
    if (onClose) {
      onClose({}, "backdropClick")
    }
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Portfolio
        </ListItem>
        <ListItem sx={{ ...item, ...itemCategory }}>
          <Avatar alt="Ekaterina" src="/media/kotay.jpg" />
          <ListItemText></ListItemText>
          <ListItemText>Ekaterina</ListItemText>
        </ListItem>
        {navCategories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, url }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={active} sx={item} onClick={() => handleSelectClick(childId)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}