import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PublicIcon from '@mui/icons-material/Public';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import TimerIcon from '@mui/icons-material/Timer';
import SettingsIcon from '@mui/icons-material/Settings';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from './Search';
import { useAuth } from './AuthProvider';

const lightColor = 'rgba(255, 255, 255, 0.7)';

interface HeaderProps {
  onDrawerToggle: () => void;
}

const AdminHeader = () => {
  let auth = useAuth();
  if (!auth.user) {
    return <></>
  } else {
    return (
      <>
        <Grid item>
          <Tooltip title="Alerts • No alerts">
            <IconButton color="inherit">
              <MailOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <IconButton color="inherit" sx={{ p: 0.5 }}>
            <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
          </IconButton>
        </Grid>
      </>
    )
  }
}

export default function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;

  return (
    <React.Fragment>
      <AppBar color="default" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <AdminHeader/>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}