import * as React from 'react';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function ContactMe() {
  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>     
    <ListItem sx={{ py: 2, px: 3 }}>      
       <ListItemText> You can email me : katya.art2002@gmail.com</ListItemText>      
     </ListItem>          
 </Paper>
  );
}