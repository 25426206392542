import * as React from 'react';
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from '../../app/store';
import { fetchVideos, selectVideoIds } from './../../reducer/videoSlice'
import Preview from './Preview';

const Previews = () => {
  const dispatch = useAppDispatch();
  
  React.useEffect(() => {
    dispatch(fetchVideos());
  }, []);

  const videoIds = useAppSelector(selectVideoIds)
  console.log(videoIds)
  return (
    <>
      {videoIds.map((id) => (         
          <Preview videoId={id}/>
        ))}
    </>
  )
}

export default Previews;
